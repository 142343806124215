import { Row, Typography, useTranslate } from "@peersyst/react-components";
import { CSSProperties } from "styled-components";
import { ReactNode } from "react";
import clsx from "clsx";

interface TextFieldLabelProps {
    label: string;
    showOptional?: boolean;
    required?: boolean;
    className?: string;
    style?: CSSProperties;
    helper?: ReactNode;
}

export default function TextFieldLabel({
    label,
    showOptional: showOptionalProp = false,
    required,
    className,
    style,
}: TextFieldLabelProps): JSX.Element {
    const translate = useTranslate();
    const showOptional = showOptionalProp && !required;

    return (
        <Row gap={"0.5rem"} className={clsx(className, "TextFieldLabel")} style={style}>
            <Typography variant="h6Strong">{label}</Typography>
            {showOptional && (
                <Typography variant="h6Regular" light>
                    {translate("optional").capitalize()}
                </Typography>
            )}
        </Row>
    );
}
