import { useSetTab } from "@peersyst/react-components";
import useVerifyEmail from "../../queries/useVerifyEmail";
import useTranslate from "../../../locale/hooks/useTranslate";
import { VerifyEmailTab } from "../../components/navigation/VerifyEmailTabs/VerifyEmailTabs";
import EmailForm from "../../components/input/EmailForm";

export interface VerifyEmailFormFields {
    token: string;
}

export default function VerifyEmailForm(): JSX.Element {
    const translate = useTranslate();
    const setTab = useSetTab();

    const { mutate: verifyEmail, isLoading } = useVerifyEmail();

    const onVerifyEmail = async (data: VerifyEmailFormFields) => {
        await verifyEmail(data);
    };

    return (
        <EmailForm
            onSubmit={onVerifyEmail}
            onResend={() => setTab(VerifyEmailTab.RESEND_EMAIL)}
            loading={isLoading}
            action={translate("verify")}
        />
    );
}
