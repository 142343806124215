import styled, { css } from "styled-components";

export const LoadingLayoutRoot = styled.div(
    ({ theme }) => css`
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        max-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${theme.palette.gradient.greenViolet};
    `,
);
