import { PartialDappDto } from "../../../common/models";
import DApp from "./DApp";
import { IPartialDApp } from "../../../ui/adapter/entity/IDApp";

export class PartialDApp extends DApp implements IPartialDApp {
    constructor() {
        super();
    }

    static fromDto(dto: PartialDappDto): PartialDApp {
        return {
            id: dto.id,
            name: dto.name,
            description: dto.description,
            email: dto.email,
            projectUrl: dto.projectUrl,
            supportUrl: dto.supportUrl,
            apiKey: dto.apiKey,
            termsUrl: dto.termsUrl,
            privacyPolicyUrl: dto.privacyPolicyUrl,
            image: dto.image,
            createdAt: dto.createdAt,
            updatedAt: dto.updatedAt,
        };
    }
}
