import { useEffect, useState } from "react";
import useUserInfo from "../../user/queries/useUserInfo";
import useVerifySession from "../../auth/queries/useVerifySession";
import useUserState from "ui/adapter/state/useUserState";

export function useLoad(): boolean {
    const [loading, setLoading] = useState(true);
    const { loggedIn } = useUserState();

    const { data: verifiedSession, refetch: fetchSession } = useVerifySession({});

    const { refetch: prefetchUserInfo } = useUserInfo();

    useEffect(() => {
        (async function () {
            await fetchSession();

            if (verifiedSession && loggedIn) await prefetchUserInfo();
            setLoading(false);
        })();
    }, [verifiedSession, loggedIn]);

    return loading;
}
