import LocalStorageRepository from "../common/LocalStorageRepository";
import { IAuthTokenRepository } from "../../../domain/adapter/repository/IAuthTokenRepository";

export default class AuthTokenRepository extends LocalStorageRepository<string> implements IAuthTokenRepository {
    constructor() {
        super("token");
    }

    getToken(): Promise<string | undefined> {
        return this.get();
    }

    setToken(token: string): Promise<void> {
        return this.set(token);
    }

    removeToken(): Promise<void> {
        return this.clear();
    }
}
