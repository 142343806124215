import UserErrors from "../user/UserErrors";
import AuthErrors from "../auth/AuthErrors";
import DAppErrors from "../dapp/DAppErrors";

// Merge all module error codes here
const DomainErrorCodes = { ...AuthErrors, ...UserErrors, ...DAppErrors };

export type DomainErrorCode = keyof typeof DomainErrorCodes;

export default DomainErrorCodes;
