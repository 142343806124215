import { TabPanel, Tabs } from "@peersyst/react-components";
import SendRecoverPasswordForm from "../../../containers/SendRecoverPasswordEmailForm/SendRecoverPasswordForm";
import RecoverPasswordForm from "../../../containers/RecoverPasswordForm/RecoverPasswordForm";

export enum RecoverPasswordTab {
    SET_EMAIL,
    SET_NEW_PASSWORD,
}

export default function RecoverPasswordTabs(): JSX.Element {
    const tabs = [
        {
            tabIndex: RecoverPasswordTab.SET_EMAIL,
            tabContent: <SendRecoverPasswordForm />,
        },
        {
            tabIndex: RecoverPasswordTab.SET_NEW_PASSWORD,
            tabContent: <RecoverPasswordForm />,
        },
    ];

    return (
        <Tabs css={{ flex: 1 }}>
            {tabs.map(({ tabIndex, tabContent }) => (
                <TabPanel key={tabIndex} index={tabIndex}>
                    {tabContent}
                </TabPanel>
            ))}
        </Tabs>
    );
}
