import DropdownMenuHeader from "../../components/input/DropdownMenuHeader/DropdownMenuHeader";
import useUserInfo from "../../../user/queries/useUserInfo";
import { UserIcon } from "../../icons";
import useUserState from "../../../adapter/state/useUserState";
import getEmailAddressFields from "../../utils/email-address";

export default function AppBarHeader(): JSX.Element {
    const { isLoading } = useUserInfo();

    const { email } = useUserState();

    return <DropdownMenuHeader title={getEmailAddressFields(email).localPart} HeaderIcon={UserIcon} loading={isLoading} />;
}
