import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import LoggedInRoute from "ui/auth/containers/LoggedInRoute/LoggedInRoute";
import GradientLayout from "ui/common/components/layout/GradientLayout/GradientLayout";

export enum PlaygroundRoutes {
    PLAYGROUND = "/playground/:dappId",
}

const PlaygroundScreen = lazy(() => import("../pages/PlaygroundScreen"));

export const usePlaygroundRoutes = (): RouteObject[] => {
    return [
        {
            path: PlaygroundRoutes.PLAYGROUND,
            element: (
                <LoggedInRoute>
                    <GradientLayout>
                        <PlaygroundScreen />
                    </GradientLayout>
                </LoggedInRoute>
            ),
        },
    ];
};
