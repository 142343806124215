import BaseLogo from "../BaseLogo/BaseLogo";
import { BaseLogoProps } from "../BaseLogo/BaseLogo.types";
import { CSSProperties } from "styled-components";
import { ReactComponent as FullLogoSvg } from "ui/assets/images/FullLogo.svg";

export interface FullLogoProps extends Omit<BaseLogoProps, "children"> {
    className?: string;
    style?: CSSProperties;
}

export default function FullLogo(props: FullLogoProps): JSX.Element {
    return (
        <BaseLogo {...props}>
            <FullLogoSvg />
        </BaseLogo>
    );
}
