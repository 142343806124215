/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthCredentialsDto } from "common/models";
import type { LoginRequest } from "common/models";
import type { RecoverPasswordRequest } from "common/models";
import type { ResetPasswordRequest } from "common/models";
import type { ValidateEmailRequest } from "common/models";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AuthenticateApi {
    /**
     * Authenticate user with email
     * @param requestBody
     * @returns AuthCredentialsDto
     * @throws ApiError
     */
    public static login(requestBody: LoginRequest): CancelablePromise<AuthCredentialsDto> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/auth/login",
            body: requestBody,
            mediaType: "application/json",
        });
    }

    /**
     * Authenticate user with Google
     * @returns any
     * @throws ApiError
     */
    public static googleAuth(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/auth/google",
        });
    }

    /**
     * Google auth callback
     * @returns any
     * @throws ApiError
     */
    public static googleAuthRedirect(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/auth/google/callback",
        });
    }

    /**
     * Verify user email
     * @param requestBody
     * @returns AuthCredentialsDto
     * @throws ApiError
     */
    public static verifyEmail(requestBody: ValidateEmailRequest): CancelablePromise<AuthCredentialsDto> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/auth/verify-email",
            body: requestBody,
            mediaType: "application/json",
        });
    }

    /**
     * Request Password Reset
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static requestResetPassword(requestBody: RecoverPasswordRequest): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/auth/recover-password",
            body: requestBody,
            mediaType: "application/json",
        });
    }

    /**
     * Password Reset
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static resetPassword(requestBody: ResetPasswordRequest): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: "POST",
            url: "/api/auth/reset-password",
            body: requestBody,
            mediaType: "application/json",
        });
    }
}
