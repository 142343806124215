/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CredentialsVerification } from "common/models";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class PlaygroundApi {
    /**
     * Create a new sign-in-request
     * @param xTimestamp The timestamp of the request
     * @param xSignature The signature of the request
     * @param xApiKey The API key of the dApp
     * @returns CredentialsVerification
     * @throws ApiError
     */
    public static verify(xTimestamp?: string, xSignature?: string, xApiKey?: string): CancelablePromise<CredentialsVerification> {
        return __request(OpenAPI, {
            method: "GET",
            url: "/api/playground/verify",
            headers: {
                "x-timestamp": xTimestamp,
                "x-signature": xSignature,
                "x-api-key": xApiKey,
            },
        });
    }
}
