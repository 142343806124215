import { ModalProvider } from "@peersyst/react-components";
import { Suspense } from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { useLoad } from "../common/hooks/useLoad";
import { useAuthRoutes } from "../auth/router/AuthRouter";
import config from "../../common/config/config";
import { useAppRoutes } from "../dapp/router/DAppRouter";
import LoadingPage from "../common/pages/LoadingPage/LoadingPage";
import NotFoundPage from "../common/pages/NotFoundPage/NotFoundPage";
import { usePlaygroundRoutes } from "ui/playground/router/PlaygroundRouter";

const Routes = () => {
    const authRoutes = useAuthRoutes();
    const appRoutes = useAppRoutes();
    const playgroundRoutes = usePlaygroundRoutes();

    const routes = [...authRoutes, ...appRoutes];

    if (config.playgroundEnabled) routes.push(...playgroundRoutes);
    routes.push({ path: "*", element: <NotFoundPage /> });

    return useRoutes(routes);
};

const Router = (): JSX.Element => {
    const loading = useLoad();

    return (
        <BrowserRouter basename={config.publicUrl}>
            <ModalProvider>
                <ScrollToTop />
                <Suspense fallback={<LoadingPage />}>{loading ? <LoadingPage /> : <Routes />}</Suspense>
            </ModalProvider>
        </BrowserRouter>
    );
};

export default Router;
