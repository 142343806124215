import { Outlet, RouteObject } from "react-router-dom";
import GradientLayout from "../../common/components/layout/GradientLayout/GradientLayout";
import { lazy } from "react";
import VerifyEmailPage from "../pages/VerifyEmailPage/VerifyEmailPage";
import LoggedOutRoute from "../containers/LoggedOutRoute/LoggedOutRoute";
import RecoverPasswordPage from "../pages/RecoverPasswordPage/RecoverPasswordPage";

export enum AuthRoutes {
    MAIN = "/auth",
    LOGIN = "/auth/login",
    REGISTER = "/auth/register",
    VERIFY_EMAIL = "/auth/verify-email",
    RECOVER_PASSWORD = "/auth/recover-password",
}

const LoginPage = lazy(() => import("../pages/LoginPage/LoginPage"));
const RegisterPage = lazy(() => import("../pages/RegisterPage/RegisterPage"));

export const useAuthRoutes = (): RouteObject[] => {
    return [
        {
            path: AuthRoutes.MAIN,
            element: (
                <LoggedOutRoute>
                    <GradientLayout>
                        <Outlet />
                    </GradientLayout>
                </LoggedOutRoute>
            ),
            children: [
                { path: AuthRoutes.LOGIN, element: <LoginPage /> },
                { path: AuthRoutes.REGISTER, element: <RegisterPage /> },
                { path: AuthRoutes.RECOVER_PASSWORD, element: <RecoverPasswordPage /> },
                { path: AuthRoutes.VERIFY_EMAIL, element: <VerifyEmailPage /> },
            ],
        },
    ];
};
