import MainLayout from "../../components/layout/MainLayout/MainLayout";
import GradientLayout from "../../components/layout/GradientLayout/GradientLayout";
import NotFoundBanner from "../../components/display/NotFoundBanner/NotFoundBanner";
import useUserState from "../../../adapter/state/useUserState";

export default function NotFoundPage(): JSX.Element {
    const { loggedIn } = useUserState();

    const Layout = loggedIn ? MainLayout : GradientLayout;

    return (
        <Layout>
            {loggedIn}
            <NotFoundBanner logged={loggedIn} />
        </Layout>
    );
}
