import styled, { css } from "styled-components";
import { Col, Row } from "@peersyst/react-components";
import { spacing } from "../../../../config/theme/spacing";
import { CardProps, CardSize } from "./Card.types";

const cardSizes: Record<keyof CardSize, string> = {
    sm: "20rem",
    md: "30rem",
    lg: "40rem",
    full: "100%",
};

export const CardRoot = styled(Col)<CardProps>(
    ({ theme, size }) => css`
        height: fit-content;
        background-color: ${theme.palette.components.card};
        border-radius: ${theme.borderRadiusLg};
        width: ${cardSizes[size || "md"]};
        min-width: 40%;
    `,
);

export const CardHeaderRoot = styled(Row)(
    ({ theme }) => css`
        padding: 1.25rem 0;
        border-bottom: 1px solid ${theme.palette.overlay[100]["8%"]};
    `,
);

export const CardContentRoot = styled(Row)(
    () => css`
        padding: ${spacing[8]} ${spacing[7]};
    `,
);

export const CardFooterRoot = styled(Row)(
    ({ theme }) => css`
        padding: ${spacing[8]} ${spacing[7]};
        border-top: 1px solid ${theme.palette.overlay[100]["8%"]};
    `,
);
