import Factory from "common/utils/Factory";
import RepositoryFactory from "domain/adapter/RepositoryFactory";
import { IAuthController } from "./controllers/IAuthController";
import { AuthController } from "../../domain/auth/controllers/AuthController";
import { AuthenticateApi, DappsApi, PlaygroundApi, SignInRequestApi, TransactionRequestApi, UserApi } from "../../data-access/api/service";
import UserController from "../../domain/user/controllers/UserController";
import { IUserController } from "./controllers/IUserController";
import { IDAppController } from "./controllers/IDAppController";
import { DAppController } from "../../domain/dapp/controllers/DAppController";
import dappState from "../../domain/dapp/state/dappState";
import { IFileController } from "./controllers/IFileController";
import { FileController } from "../../domain/file/controllers/FileController";
import { HelperFileApi } from "../../data-access/api/service/helper/HelperFileApi";
import userState from "../../domain/user/state/userState";
import { IPlaygroundController } from "./controllers/IPlaygroundController";
import { PlaygroundController } from "domain/playground/controllers/PlaygroundController";
import playgroundCredentialsState from "domain/playground/state/playgroundCredentialsState";
import playgroundSignInRequestsState from "domain/playground/state/playgroundSignInRequestsState";

export default class ControllerFactory extends Factory {
    private static _authController: IAuthController;
    private static _userController: IUserController;
    private static _dappController: IDAppController;
    private static _fileController: IFileController;
    private static _playgroundController: IPlaygroundController;

    static get authController(): IAuthController {
        return this.resolve(
            this._authController,
            () => new AuthController(AuthenticateApi, RepositoryFactory.authTokenRepository, userState),
        );
    }

    static get userController(): IUserController {
        return this.resolve(this._userController, () => new UserController(UserApi, userState));
    }

    static get dappController(): IDAppController {
        return this.resolve(this._dappController, () => new DAppController(DappsApi, dappState));
    }

    static get fileController(): IFileController {
        return this.resolve(this._fileController, () => new FileController(HelperFileApi));
    }

    static get playgroundController(): IPlaygroundController {
        return this.resolve(
            this._playgroundController,
            () =>
                new PlaygroundController(
                    SignInRequestApi,
                    playgroundCredentialsState,
                    TransactionRequestApi,
                    playgroundSignInRequestsState,
                    PlaygroundApi,
                ),
        );
    }
}
