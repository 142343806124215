import { IUserController } from "../../../ui/adapter/controllers/IUserController";
import { PrivateUserDto, UserDto } from "../../../common/models";
import DomainError from "../../error/DomainError";
import { IUserApi } from "../../adapter/api/IUserApi";
import { RegisterFormFields } from "../types/form.types";
import { SendVerificationTokenFormFields } from "../types/form.types";
import State from "../../common/State";
import { IUserState } from "../state/userState";

export default class UserController implements IUserController {
    constructor(private readonly userApi: IUserApi, private readonly userState: State<IUserState>) {}

    public async register({ name, email, password }: RegisterFormFields): Promise<PrivateUserDto> {
        try {
            return await this.userApi.register({ name, email, password });
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async resendVerificationEmail({ email }: SendVerificationTokenFormFields): Promise<void> {
        try {
            await this.userApi.verificationToken({ email });
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async info(): Promise<UserDto> {
        try {
            const user = await this.userApi.info();
            const { loggedIn } = this.userState.getState();
            this.userState.setState({ loggedIn, ...user });
            return user;
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }
}
