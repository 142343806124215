import BaseLogo from "../BaseLogo/BaseLogo";
import { ReactComponent as FullRoundedLogoSvg } from "ui/assets/images/RoundedLogo.svg";
import { BaseLogoProps } from "../BaseLogo/BaseLogo.types";
import { CSSProperties } from "styled-components";

export interface RoundedLogoProps extends Omit<BaseLogoProps, "children"> {
    className?: string;
    style?: CSSProperties;
}

export default function RoundedLogo(props: RoundedLogoProps): JSX.Element {
    return (
        <BaseLogo {...props}>
            <FullRoundedLogoSvg />
        </BaseLogo>
    );
}
