import { createStore } from "zustand/vanilla";

export type IUserState = {
    id: number;
    name: string;
    email: string;
    googleAuth: boolean;
    loggedIn: boolean;
};

const userState = createStore<IUserState>(() => ({ id: 0, name: "", email: "", googleAuth: false, loggedIn: false }));

export default userState;
