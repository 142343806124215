import { Select as BaseSelect } from "@peersyst/react-components";
import { cx } from "@peersyst/react-utils";
import { SelectProps } from "./Select.types";

function Select<T, Multiple extends boolean = false>({
    size = "md",
    variant = "filled",
    rounded = false,
    className,
    ...rest
}: SelectProps<T, Multiple>): JSX.Element {
    return <BaseSelect className={cx(size?.capitalize(), variant?.capitalize(), rounded ? "Rounded" : undefined, className)} {...rest} />;
}

export default Select;
