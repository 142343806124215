import Title from "../../../../common/components/display/Title/Title";
import Card from "../../../../common/components/display/Card/Card";
import useTranslate from "../../../../locale/hooks/useTranslate";
import RecoverPasswordTabs from "../../navigation/RecoverPasswordTabs/RecoverPasswordTabs";

export default function RecoverPasswordCard(): JSX.Element {
    const translate = useTranslate();

    return (
        <Card>
            {{
                header: <Title title={translate("recoverYourPassword")} />,
                content: <RecoverPasswordTabs />,
            }}
        </Card>
    );
}
