import { IDAppController } from "../../../ui/adapter/controllers/IDAppController";
import { CreateDappRequest, UpdateDappRequest } from "../../../common/models";
import { IDAppApi } from "../../adapter/api/IDAppApi";
import DomainError from "../../error/DomainError";
import { IDAppState } from "../state/dappState";
import State from "../../common/State";
import CompleteDApp from "../entity/CompleteDApp";
import DomainErrorCodes from "../../error/DomainErrorCodes";
import { ICompleteDApp, IPartialDApp } from "../../../ui/adapter/entity/IDApp";
import { PartialDApp } from "../entity/PartialDApp";

export class DAppController implements IDAppController {
    constructor(private readonly dAppApi: IDAppApi, private readonly dAppState: State<IDAppState>) {}

    public async createDApp(dapp: CreateDappRequest): Promise<ICompleteDApp> {
        try {
            const dApp = await this.dAppApi.create(dapp);
            this.dAppState.setState({ id: dApp.id, name: dApp.name, apiKey: dApp.apiKey, apiSecret: dApp.apiSecret });
            return CompleteDApp.fromDto(dApp);
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async updateDApp(id: number, dapp: UpdateDappRequest): Promise<void> {
        try {
            await this.dAppApi.update(id, dapp);
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async getDApp(id?: number): Promise<IPartialDApp> {
        try {
            if (!id) throw new DomainError(DomainErrorCodes.DAPP_NOT_DEFINED);
            const dApp = await this.dAppApi.findOne(id);
            this.dAppState.setState({ id: dApp.id, name: dApp.name, apiKey: dApp.apiKey, apiSecret: undefined });
            return PartialDApp.fromDto(dApp);
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async deleteDApp(id: number): Promise<void> {
        try {
            await this.dAppApi.remove(id);
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async getDApps(): Promise<IPartialDApp[]> {
        try {
            return await this.dAppApi.findAll();
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async generateApiSecret(id: number): Promise<void> {
        try {
            const { apiSecret } = await this.dAppApi.generateNewApiSecret(id);
            this.dAppState.setState({ apiSecret });
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }
}
