import { TabPanel, Tabs } from "@peersyst/react-components";
import VerifyEmailForm from "../../../containers/VerifyEmailForm/VerifyEmailForm";
import SendVerificationEmailForm from "../../../containers/SendVerificationEmailForm/SendVerificationEmailForm";

export enum VerifyEmailTab {
    VERIFY_EMAIL,
    RESEND_EMAIL,
}

export default function VerifyEmailTabs(): JSX.Element {
    const tabs = [
        {
            tabIndex: VerifyEmailTab.VERIFY_EMAIL,
            tabContent: <VerifyEmailForm />,
        },
        {
            tabIndex: VerifyEmailTab.RESEND_EMAIL,
            tabContent: <SendVerificationEmailForm />,
        },
    ];

    return (
        <Tabs css={{ flex: 1 }}>
            {tabs.map(({ tabIndex, tabContent }) => (
                <TabPanel key={tabIndex} index={tabIndex}>
                    {tabContent}
                </TabPanel>
            ))}
        </Tabs>
    );
}
