import { Typography } from "@peersyst/react-components";
import TextField from "../../../common/components/input/TextField/TextField";
import TextFieldLabel from "../../../common/components/input/TextFieldLabel/TextFieldLabel";
import Button from "../../../common/components/input/Button/Button";
import useTranslate from "../../../locale/hooks/useTranslate";
import CardForm from "../../../common/components/layout/CardForm/CardForm";

export interface SendEmailFormFields {
    email: string;
}

export interface SendEmailFormProps {
    onSubmit: (data: SendEmailFormFields) => void;
    loading: boolean;
}

export default function SendEmailForm({ onSubmit, loading }: SendEmailFormProps): JSX.Element {
    const translate = useTranslate();

    return (
        <CardForm onSubmit={onSubmit}>
            <Typography variant="h5Light" textAlign="center">
                {translate("introduceEmail")}
            </Typography>
            <TextField
                label={<TextFieldLabel label={translate("yourEmail")} />}
                type="email"
                name="email"
                placeholder="ckb@ckb.com"
                required
                validators={{ email: true }}
            />
            <Button type="submit" variant="primary" loading={loading} disabled={loading}>
                {translate("sendEmail")}
            </Button>
        </CardForm>
    );
}
