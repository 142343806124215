import ControllerFactory from "../../adapter/ControllerFactory";
import { useQuery } from "react-query";
import { obtainCookie } from "../../common/utils/cookie";
import { Queries } from "../../query/queries";

export interface UseVerifySessionOptions {
    onSuccess?: () => void;
    onError?: (error: any) => void;
}

export default function (options: UseVerifySessionOptions) {
    const cookieToken = () => obtainCookie("access_token");
    return useQuery(Queries.VERIFY_SESSION, () => ControllerFactory.authController.verifySession(cookieToken), {
        onSuccess: options.onSuccess,
        onError: options.onError,
    });
}
