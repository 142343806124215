import { createTheme, ThemePalette } from "@peersyst/react-components";
import theme from "./theme";
import { ThemeOverlay } from "./theme.declarations";

export const violet = "#6D45F5";
export const red = "#FF1717";
export const orange = "#E3935B";
export const blue = "#5F8AFA";
export const white = "#FFFFFF";

export const green: ThemePalette["green"] = {
    200: "#1ED882",
    400: "#34AEAE",
};

export const gray: ThemePalette["gray"] = {
    10: "#E7E9EB",
    20: "#A7A7A7",
};

export const black: ThemePalette["black"] = {
    10: "#494E4E",
    20: "#3F4246",
    30: "#292929",
};

// Overlay - black
export const overlay100: ThemeOverlay = {
    "6%": "#2929290F",
    "8%": "#29292914",
    "12%": "#2929291F",
    "24%": "#2929293D",
    "48%": "#2929297A",
};

// Overlay - gray
export const overlay300: ThemeOverlay = {
    "6%": "#A7A7A70F",
    "8%": "#A7A7A714",
    "12%": "#A7A7A71F",
    "24%": "#A7A7A73D",
    "48%": "#A7A7A77A",
};

export const overlay: ThemePalette["overlay"] = {
    100: overlay100,
    300: overlay300,
};

export const gradient: ThemePalette["gradient"] = {
    greenDarkGreen: "linear-gradient(116.02deg, " + green[200] + " 16.4%, " + green[400] + " 83.6%)",
    greenViolet: "linear-gradient(135.5deg, " + green[200] + " 0%, " + violet + " 133.21%)",
};

const lightTheme = createTheme({
    ...theme,
    palette: {
        mode: "light",
        primary: green[200],
        background: gray[10],
        border: overlay[100]["8%"],
        text: black[30],
        backdrop: overlay[100]["24%"],
        status: {
            info: blue,
            warning: orange,
            error: red,
            success: green[200],
        },
        gray,
        blue,
        orange,
        red,
        green,
        violet,
        black,
        white,
        overlay,
        gradient,

        components: {
            card: white,
            appBar: white,
        },
    },
});

export default lightTheme;
