export default class DApp {
    id: number;
    name: string;
    description: string;
    email: string;
    projectUrl: string;
    supportUrl: string;
    apiKey: string;
    termsUrl?: string;
    privacyPolicyUrl?: string;
    image?: string;
    createdAt: string;
    updatedAt: string;

    constructor() {
        this.id = 0;
        this.name = "";
        this.description = "";
        this.email = "";
        this.projectUrl = "";
        this.supportUrl = "";
        this.apiKey = "";
        this.termsUrl = "";
        this.privacyPolicyUrl = "";
        this.image = "";
        this.createdAt = "";
        this.updatedAt = "";
    }
}
