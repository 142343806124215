import Card from "../../../../common/components/display/Card/Card";
import Title from "../../../../common/components/display/Title/Title";
import useTranslate from "../../../../locale/hooks/useTranslate";
import VerifyEmailTabs from "../../navigation/VerifyEmailTabs/VerifyEmailTabs";

export default function VerifyEmailCard(): JSX.Element {
    const translate = useTranslate();

    return (
        <Card>
            {{
                header: <Title title={translate("validateYourEmail")} />,
                content: <VerifyEmailTabs />,
            }}
        </Card>
    );
}
