import styled, { css } from "styled-components";
import { emphasize } from "@peersyst/react-utils";
import { Typography } from "@peersyst/react-components";

export const ResendEmailButton = styled(Typography)(
    ({ theme }) => css`
        color: ${theme.palette.primary};
        text-transform: lowercase;

        &:hover {
            cursor: pointer;
            color: ${emphasize(theme.palette.primary, 0.15)};
            text-transform: lowercase;
        }
    `,
);
