import { UserIcon } from "../../../icons";
import { Row, Skeleton, Typography } from "@peersyst/react-components";

interface DropdownMenuHeaderProps {
    title: string | undefined;
    HeaderIcon: typeof UserIcon;
    loading?: boolean;
}

export default function DropdownMenuHeader({ title, HeaderIcon, loading = false }: DropdownMenuHeaderProps) {
    return (
        <Row flex={1} gap={"0.5rem"} justifyContent="center" alignItems="center">
            <HeaderIcon color="white" fontSize="1.25rem" />
            <Skeleton loading={loading}>
                <Typography variant="h5Regular" color={"white"}>
                    {title}
                </Typography>
            </Skeleton>
        </Row>
    );
}
