import SendEmailForm, { SendEmailFormFields } from "../../components/input/SendEmailForm";
import useRecoverPassword from "../../queries/useRecoverPassword";
import { useSetTab, useToast } from "@peersyst/react-components";
import useTranslate from "../../../locale/hooks/useTranslate";
import { RecoverPasswordTab } from "../../components/navigation/RecoverPasswordTabs/RecoverPasswordTabs";

export default function SendRecoverPasswordForm(): JSX.Element {
    const translate = useTranslate();
    const { showToast } = useToast();
    const setTab = useSetTab();

    const onSendSuccess = () => {
        showToast(translate("emailResendSuccess"), { type: "success" });
        setTab(RecoverPasswordTab.SET_NEW_PASSWORD);
    };

    const { mutate: sendRecoverPasswordEmail, isLoading } = useRecoverPassword({ onSuccess: onSendSuccess });

    const handleSubmit = (data: SendEmailFormFields) => sendRecoverPasswordEmail(data);

    return <SendEmailForm onSubmit={handleSubmit} loading={isLoading} />;
}
