import { PropsWithChildren } from "react";
import { AuthRoutes } from "../../router/AuthRouter";
import ProtectedRoute from "../../../router/components/ProtectedRoute/ProtectedRoute";
import useUserState from "../../../adapter/state/useUserState";

export default function LoggedInRoute({ children }: PropsWithChildren): JSX.Element {
    const { loggedIn } = useUserState();
    return (
        <ProtectedRoute isAllowed={loggedIn} redirectPath={AuthRoutes.LOGIN}>
            {children}
        </ProtectedRoute>
    );
}
