import useTranslate from "../../locale/hooks/useTranslate";
import useLogout from "../../auth/queries/useLogout";
import { useNavigate } from "react-router-dom";
import { AuthRoutes } from "../../auth/router/AuthRouter";
import { DropdownMenuOption } from "../components/input/DropdownMenu/DropdownMenu.types";

export default function useAppBarOptions(): DropdownMenuOption[] {
    const translate = useTranslate();
    const navigate = useNavigate();
    const { mutate: logout } = useLogout();

    const onLogout = () => {
        logout();
        navigate(AuthRoutes.LOGIN);
    };

    const options: DropdownMenuOption[] = [
        {
            label: translate("logout"),
            onClick: () => onLogout(),
        },
    ];

    return options;
}
