import { Typography } from "@peersyst/react-components";
import TextField from "../../../common/components/input/TextField/TextField";
import TextFieldLabel from "../../../common/components/input/TextFieldLabel/TextFieldLabel";
import { ResendEmailButton } from "../../containers/VerifyEmailForm/VerifyEmailForm.styles";
import Button from "../../../common/components/input/Button/Button";
import useTranslate from "../../../locale/hooks/useTranslate";
import CardForm from "../../../common/components/layout/CardForm/CardForm";

export interface EmailFormFields {
    token: string;
}

export interface EmailFormProps {
    onSubmit: (data: EmailFormFields) => void;
    onResend: () => void;
    loading: boolean;
    action: string;
}

export default function EmailForm({ onSubmit, loading, action, onResend }: EmailFormProps): JSX.Element {
    const translate = useTranslate();

    return (
        <CardForm onSubmit={onSubmit}>
            <Typography variant="h5Light" textAlign="center">
                {translate("weSentCodeToEmail")}
            </Typography>
            <TextField
                label={<TextFieldLabel label={translate("code")} />}
                type="text"
                name="token"
                required
                validators={{ minChars: 6 }}
            />
            <Typography variant="h5Regular" textAlign="center" light>
                {translate("didntReceiveEmail")}
                <ResendEmailButton variant="h5Regular" onClick={onResend}>
                    {translate("resendEmail")}
                </ResendEmailButton>
            </Typography>
            <Button type="submit" variant="primary" loading={loading} disabled={loading}>
                {action}
            </Button>
        </CardForm>
    );
}
