import styled, { css } from "styled-components";
import ConditionalLink from "ui/router/components/ConditionalLink/ConditionalLink";
import { BaseLogoRootProps } from "./BaseLogo.types";

export const BaseLogoRoot = styled(ConditionalLink)<BaseLogoRootProps>(
    ({ color, condition }) => css`
        svg {
            fill: currentColor;
            color: ${color};
        }

        cursor: ${condition ? "pointer" : "default"};
    `,
);
