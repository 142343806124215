import { PropsWithChildren } from "react";
import useUserState from "../../../adapter/state/useUserState";
import ProtectedRoute from "../../../router/components/ProtectedRoute/ProtectedRoute";

import { AppRoutes } from "../../../dapp/router/DAppRouter";

export default function LoggedOutRoute({ children }: PropsWithChildren): JSX.Element {
    const { loggedIn } = useUserState();

    return (
        <ProtectedRoute isAllowed={!loggedIn} redirectPath={AppRoutes.MY_APPS}>
            {children}
        </ProtectedRoute>
    );
}
