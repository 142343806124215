interface GetEmailAddressFieldsReturn {
    localPart: string;
    domain: string;
}
export default function getEmailAddressFields(emailAddress: string): GetEmailAddressFieldsReturn {
    const atSignPos = emailAddress.indexOf("@");
    return {
        localPart: emailAddress.slice(0, atSignPos),
        domain: emailAddress.slice(atSignPos + 1),
    };
}
