import ResetPasswordForm, { ResetPasswordFormFields } from "../../components/input/ResetPasswordForm";
import useResetPassword from "../../queries/useResetPassword";
import { useSetTab, useToast } from "@peersyst/react-components";
import useTranslate from "../../../locale/hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import { AuthRoutes } from "../../router/AuthRouter";
import { RecoverPasswordTab } from "../../components/navigation/RecoverPasswordTabs/RecoverPasswordTabs";

export default function RecoverPasswordForm(): JSX.Element {
    const { showToast } = useToast();
    const translate = useTranslate();
    const navigate = useNavigate();
    const setTab = useSetTab();

    const onResetSuccess = () => {
        showToast(translate("passwordResetSuccess"), { type: "success" });
        navigate(AuthRoutes.LOGIN);
    };

    const { mutate: resetPassword, isLoading } = useResetPassword({ onSuccess: onResetSuccess });
    const handleSubmit = (data: ResetPasswordFormFields) => resetPassword(data);

    return <ResetPasswordForm onSubmit={handleSubmit} loading={isLoading} onResend={() => setTab(RecoverPasswordTab.SET_EMAIL)} />;
}
