import { lazy } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import LoggedInRoute from "../../auth/containers/LoggedInRoute/LoggedInRoute";
import MainLayout from "../../common/components/layout/MainLayout/MainLayout";

export enum AppRoutes {
    MY_APPS = "/",
    CREATE_APP = "/create",
    EDIT_APP = "/edit/:id",
}

const MyDAppsPage = lazy(() => import("../pages/MyAppsPage/MyDAppsPage"));
const CreateDAppPage = lazy(() => import("../pages/CreateDAppPage/CreateDAppPage"));
const EditDAppPage = lazy(() => import("../pages/EditDAppPage/EditDAppPage"));

export const useAppRoutes = (): RouteObject[] => {
    return [
        {
            path: AppRoutes.MY_APPS,
            element: (
                <LoggedInRoute>
                    <MainLayout>
                        <Outlet />
                    </MainLayout>
                </LoggedInRoute>
            ),
            children: [
                { path: AppRoutes.MY_APPS, element: <MyDAppsPage /> },
                { path: AppRoutes.CREATE_APP, element: <CreateDAppPage /> },
                { path: AppRoutes.EDIT_APP, element: <EditDAppPage /> },
            ],
        },
    ];
};
