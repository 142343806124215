import { TextField as BaseTextField } from "@peersyst/react-components";
import { TextFieldProps } from "./TextField.types";
import TextFieldLabel from "../TextFieldLabel/TextFieldLabel";
import clsx from "clsx";

export default function TextField({
    label,
    optional = false,
    variant = "outlined",
    size = "md",
    required,
    className,
    ...rest
}: TextFieldProps): JSX.Element {
    return (
        <BaseTextField
            label={typeof label === "string" ? <TextFieldLabel label={label} required={required} showOptional={optional} /> : label}
            {...rest}
            required={required}
            className={clsx(className, variant?.capitalize(), size?.capitalize())}
        />
    );
}
