import Factory from "../../common/utils/Factory";
import { IAuthTokenRepository } from "./repository/IAuthTokenRepository";
import AuthTokenRepository from "../../data-access/repository/auth/AuthTokenRepository";

export default class RepositoryFactory extends Factory {
    private static _authTokenRepository: IAuthTokenRepository;

    static get authTokenRepository(): IAuthTokenRepository {
        return this.resolve(this._authTokenRepository, () => new AuthTokenRepository());
    }
}
