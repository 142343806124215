import { Row, Toolbar } from "@peersyst/react-components";
import { AppBarRoot } from "./AppBar.styles";
import DropdownMenu from "../../input/DropdownMenu/DropdownMenu";
import useAppBarOptions from "../../../hooks/useAppBarOptions";
import AppBarHeader from "../../../containers/AppBarHeader/AppBarHeader";
import AppBarBanner from "../../display/AppBarBanner/AppBarBanner";
import { AppBarField } from "./AppBar.types";
import Link from "ui/router/components/Link/Link";
import { config } from "common/config";
import useTranslate from "ui/locale/hooks/useTranslate";

export default function AppBar(): JSX.Element {
    const options = useAppBarOptions();
    const translate = useTranslate();

    const APP_BAR_FIELDS: AppBarField[] = [
        {
            text: translate("docs"),
            link: config.docsUrl,
        },
    ];

    return (
        <AppBarRoot>
            <Toolbar>
                <Row flex={1} alignItems="center" justifyContent="space-between">
                    <AppBarBanner />
                    <Row gap={"2rem"} justifyContent="center" alignItems="center">
                        {APP_BAR_FIELDS.map(({ text, link }) => (
                            <Link key={text} variant="h5Strong" to={link}>
                                {text}
                            </Link>
                        ))}
                        <DropdownMenu variant="filled" header={<AppBarHeader />} options={options} rounded />
                    </Row>
                </Row>
            </Toolbar>
        </AppBarRoot>
    );
}
