import { CardContentRoot, CardFooterRoot, CardHeaderRoot, CardRoot } from "./Card.styles";
import { CardProps } from "./Card.types";

export default function Card({ children: { header, content, footer }, ...cardProps }: CardProps): JSX.Element {
    return (
        <CardRoot {...cardProps}>
            {header && <CardHeaderRoot justifyContent="center">{header}</CardHeaderRoot>}
            {content && <CardContentRoot>{content}</CardContentRoot>}
            {footer && <CardFooterRoot>{footer}</CardFooterRoot>}
        </CardRoot>
    );
}
