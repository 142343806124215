import { LoadingLayoutRoot } from "./LoadingLayout.styles";
import { ReactNode } from "react";
import { Col } from "@peersyst/react-components";

export interface LoadingLayoutProps {
    children?: ReactNode;
}

export default function LoadingLayout({ children }: LoadingLayoutProps): JSX.Element {
    return (
        <LoadingLayoutRoot>
            <Col justifyContent="center" alignItems="center" gap="2rem">
                {children}
            </Col>
        </LoadingLayoutRoot>
    );
}
