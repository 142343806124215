import { SvgIcon, SvgIconProps } from "@peersyst/react-components";
import { cx } from "@peersyst/react-utils";
export default function ImageIcon({ className, ...rest }: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...rest} data-testid="ImageIcon" className={cx(undefined, "Icon", className)} fill="none">
            <path
                d="M15.5 10C16.3284 10 17 9.32843 17 8.5C17 7.67157 16.3284 7 15.5 7C14.6716 7 14 7.67157 14 8.5C14 9.32843 14.6716 10 15.5 10Z"
                fill="#292929"
            />
            <path
                d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5ZM19 5H5V12.9194L8.37534 10.2191C8.74056 9.92696 9.25951 9.92696 9.62473 10.2191L13.9258 13.66L15.2929 12.2929C15.6835 11.9024 16.3166 11.9024 16.7071 12.2929L19 14.5857V5ZM5 19H19V17.4142L16 14.4142L14.7071 15.7071C14.3468 16.0674 13.7732 16.0992 13.3753 15.7809L9.00004 12.2806L5 15.4807V19Z"
                fill="#292929"
            />
        </SvgIcon>
    );
}
