import { GradientLayoutRoot } from "./GradientLayout.styles";
import { ReactNode } from "react";
import PageContent from "../PageContent/PageContent";

interface BaseGradientPageProps {
    children?: ReactNode;
}

export default function GradientLayout({ children }: BaseGradientPageProps): JSX.Element {
    return (
        <GradientLayoutRoot>
            <PageContent>{children}</PageContent>
        </GradientLayoutRoot>
    );
}
