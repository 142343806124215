import FullLogo from "../../../common/components/display/FullLogo/FullLogo";
import { Row } from "@peersyst/react-components";
import VerifyEmailCard from "../../components/display/VerifyEmailCard/VerifyEmailCard";
import { AppRoutes } from "../../../dapp/router/DAppRouter";

export default function VerifyEmailPage(): JSX.Element {
    return (
        <Row wrap wrapGap={"4rem"} flex={1} gap={"12rem"} justifyContent="center" alignItems="center">
            <FullLogo link={AppRoutes.MY_APPS} />
            <VerifyEmailCard />
        </Row>
    );
}
