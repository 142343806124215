import { IAuthController } from "../../../ui/adapter/controllers/IAuthController";
import { AuthCredentialsDto, LoginRequest, ValidateEmailRequest } from "../../../common/models";
import { IAuthenticateApi } from "../../adapter/api/IAuthenticateApi";
import { IAuthTokenRepository } from "../../adapter/repository/IAuthTokenRepository";
import DomainError from "../../error/DomainError";
import AuthErrorCodes from "../AuthErrors";
import { ResetPasswordFormFields } from "../../../ui/auth/components/input/ResetPasswordForm";
import State from "../../common/State";
import { IUserState } from "../../user/state/userState";

export class AuthController implements IAuthController {
    constructor(
        private readonly authApi: IAuthenticateApi,
        private readonly authTokenRepository: IAuthTokenRepository,
        private readonly userState: State<IUserState>,
    ) {}

    private isTokenExpired(token: string) {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const expirationTime = decodedToken.exp * 1000;
        const currentTime = Date.now();
        return currentTime > expirationTime;
    }

    private async accessTokenValid(accessToken: string | undefined): Promise<boolean> {
        const currentToken = accessToken;
        if (currentToken) return !this.isTokenExpired(currentToken);
        return false;
    }

    public async login({ email, password }: LoginRequest): Promise<AuthCredentialsDto> {
        try {
            const response = await this.authApi.login({ email, password });
            if (response.access_token) await this.authTokenRepository.setToken(response.access_token);
            this.userState.setState({ ...this.userState.getState(), loggedIn: true });
            return response;
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async logout(): Promise<void> {
        await this.authTokenRepository.removeToken();
        this.userState.setState({ ...this.userState.getState(), loggedIn: false });
    }

    public async verifyEmail({ token }: ValidateEmailRequest): Promise<AuthCredentialsDto> {
        try {
            return await this.authApi.verifyEmail({ token: token });
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async verifySession(obtainUIToken: () => string | undefined): Promise<boolean> {
        const currentToken = await this.authTokenRepository.getToken();
        const currentTokenValid = await this.accessTokenValid(currentToken);
        const prevUserState = this.userState.getState();
        if (!currentTokenValid) {
            const uiToken = obtainUIToken();
            if (!uiToken) return false;
            const cookieTokenValid = this.accessTokenValid(uiToken);
            if (!cookieTokenValid) throw new DomainError(AuthErrorCodes.SESSION_EXPIRED);
            await this.authTokenRepository.setToken(uiToken);
        }
        this.userState.setState({ ...prevUserState, loggedIn: true });
        return true;
    }

    public async sendRecoverPasswordEmail(email: string): Promise<void> {
        try {
            await this.authApi.requestResetPassword({ email });
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async resetPassword({ emailToken, password }: ResetPasswordFormFields): Promise<void> {
        try {
            await this.authApi.resetPassword({ token: emailToken, password });
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }
}
