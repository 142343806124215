import { BaseLogoProps } from "./BaseLogo.types";
import { BaseLogoRoot } from "./BaseLogo.styles";
import { useColor } from "@peersyst/react-components";
import { AppRoutes } from "../../../../dapp/router/DAppRouter";

export default function BaseLogo({ link, color: colorProp = "text", children }: BaseLogoProps): JSX.Element {
    const color = useColor(colorProp)!;
    const defaultLink = link === undefined ? AppRoutes.MY_APPS : link;

    return (
        <BaseLogoRoot color={color} to={defaultLink} condition={link !== undefined}>
            {children}
        </BaseLogoRoot>
    );
}
