import LoadingLayout from "../../components/layout/LoadingLayout/LoadingLayout";

import { Loader } from "@peersyst/react-components";
import FullLogo from "../../components/display/FullLogo/FullLogo";

export default function LoadingPage() {
    return (
        <LoadingLayout>
            <FullLogo />
            <Loader css={{ fontSize: "2rem", color: "white" }} />
        </LoadingLayout>
    );
}
