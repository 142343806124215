import Banner from "../Banner/Banner";
import { ReactComponent as FullBanner } from "../../../../assets/images/banner.svg";
import Link from "../../../../router/components/Link/Link";
import useIsMobile from "../../../hooks/useIsMobile";
import { AppRoutes } from "../../../../dapp/router/DAppRouter";

export default function AppBarBanner(): JSX.Element {
    const isMobile = useIsMobile();

    return (
        <Link to={AppRoutes.MY_APPS} variant="h5Regular">
            {isMobile ? <Banner /> : <FullBanner />}
        </Link>
    );
}
