import { DomainErrorCode } from "./DomainErrorCodes";
import { IApiError } from "../adapter/api/IApiError";

export default class DomainError extends Error {
    code: DomainErrorCode;

    constructor(code: DomainErrorCode) {
        super(code);

        this.name = "DomainError";
        this.code = code;
    }

    static fromApiError(error: IApiError): DomainError {
        if (Array.isArray(error.body.message)) return new DomainError(error.body.message[0]);
        return new DomainError(error.body.message);
    }
}
