import { useSetTab, useToast } from "@peersyst/react-components";
import useTranslate from "../../../locale/hooks/useTranslate";
import useResendEmail from "../../queries/useResendEmail";
import { VerifyEmailTab } from "../../components/navigation/VerifyEmailTabs/VerifyEmailTabs";
import SendEmailForm, { SendEmailFormFields } from "../../components/input/SendEmailForm";

export default function SendVerificationEmailForm(): JSX.Element {
    const translate = useTranslate();
    const { showToast } = useToast();
    const setTab = useSetTab();

    const onSendSuccess = () => {
        showToast(translate("emailResendSuccess"), { type: "success" });
        setTab(VerifyEmailTab.VERIFY_EMAIL);
    };

    const { mutate: resendEmail, isLoading } = useResendEmail({ onSuccess: onSendSuccess });

    const onResendEmail = (data: SendEmailFormFields) => resendEmail(data);

    return <SendEmailForm onSubmit={onResendEmail} loading={isLoading} />;
}
