import { SvgIcon, SvgIconProps } from "@peersyst/react-components";
import { cx } from "@peersyst/react-utils";
export default function WarningIcon({ className, ...rest }: Omit<SvgIconProps, "children">): JSX.Element {
    return (
        <SvgIcon {...rest} data-testid="WarningIcon" className={cx(undefined, "Icon", className)} fill="none">
            <path
                d="M12 14C11.4478 14 11 13.5523 11 13V10C11 9.44772 11.4478 9 12 9C12.5523 9 13 9.44772 13 10V13C13 13.5523 12.5523 14 12 14Z"
                fill="#292929"
            />
            <path
                d="M10.5 16.5C10.5 15.6716 11.1716 15 12 15C12.8285 15 13.5 15.6716 13.5 16.5C13.5 17.3284 12.8285 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5Z"
                fill="#292929"
            />
            <path
                d="M10.2302 3.2156C10.98 1.79093 13.0201 1.79092 13.7699 3.2156L22.1135 19.0685C22.8145 20.4003 21.8487 22 20.3437 22H3.65641C2.15139 22 1.18562 20.4003 1.88658 19.0685L10.2302 3.2156ZM20.3437 20L12 4.1471L3.65641 20L20.3437 20Z"
                fill="#292929"
            />
        </SvgIcon>
    );
}
