import { ReactNode } from "react";
import { Toolbar } from "@peersyst/react-components";
import { MainLayoutRoot } from "./MainLayout.styles";
import AppBar from "../../navigation/AppBar/AppBar";
import PageContent from "../PageContent/PageContent";

interface BasePageProps {
    toolbarOffset?: boolean;
    children?: ReactNode;
}

const MainLayout = ({ toolbarOffset = true, children }: BasePageProps): JSX.Element => (
    <MainLayoutRoot>
        <AppBar />
        {toolbarOffset && <Toolbar css={{ height: "4.75rem" }} />}
        <PageContent>{children}</PageContent>
    </MainLayoutRoot>
);

export default MainLayout;
