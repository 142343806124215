import Card from "../Card/Card";
import { Col, Row, Typography } from "@peersyst/react-components";
import { spacing } from "../../../../config/theme/spacing";
import RoundedLogo from "../RoundedLogo/RoundedLogo";
import useTranslate from "../../../../locale/hooks/useTranslate";

interface NotFoundBannerProps {
    logged: boolean | undefined;
}

export default function NotFoundBanner({ logged }: NotFoundBannerProps): JSX.Element {
    const translate = useTranslate();
    return (
        <Col flex={logged ? 0 : 1} justifyContent="center" alignItems="center" css={logged ? { margin: "12rem 0" } : {}}>
            <Card>
                {{
                    content: (
                        <Row gap={spacing[7]} alignItems="center">
                            <RoundedLogo />
                            <Col>
                                <Typography variant="h2Strong">{translate("404Title")}</Typography>
                                <Typography variant="h5Regular" light>
                                    {translate("404Description")}
                                </Typography>
                            </Col>
                        </Row>
                    ),
                }}
            </Card>
        </Col>
    );
}
