import { useMutation } from "react-query";
import ControllerFactory from "../../adapter/ControllerFactory";
import { VerifyEmailFormFields } from "../containers/VerifyEmailForm/VerifyEmailForm";
import { AuthRoutes } from "../router/AuthRouter";
import { useNavigate } from "react-router-dom";
import { useToast } from "@peersyst/react-components";
import useTranslate from "../../locale/hooks/useTranslate";

export default function useVerifyEmail() {
    const navigate = useNavigate();
    const { showToast } = useToast();
    const translate = useTranslate();
    return useMutation((data: VerifyEmailFormFields) => ControllerFactory.authController.verifyEmail(data), {
        onSuccess: () => {
            showToast(translate("emailVerificationSuccess"), { type: "success" });
            navigate(AuthRoutes.LOGIN);
        },
    });
}
