import DropdownMenuRoot from "../Select/Select";
import { ReactNode } from "react";
import { SelectProps } from "../Select/Select.types";
import { Col, SelectItem } from "@peersyst/react-components";
import Link from "../../../../router/components/Link/Link";
import { DropdownMenuOption } from "./DropdownMenu.types";

interface DropdownMenuProps extends Omit<SelectProps<any>, "placeholder" | "children" | "options"> {
    header: ReactNode;
    options: DropdownMenuOption[];
}

export default function DropdownMenu({ header, options, ...rest }: DropdownMenuProps): JSX.Element {
    return (
        <DropdownMenuRoot placeholder={header} onChange={() => undefined} {...rest}>
            {options?.map((item, index) =>
                "to" in item ? (
                    <Link key={index} to={item.to} variant="h5Regular">
                        <SelectItem value={item.label}>{item.label}</SelectItem>
                    </Link>
                ) : (
                    <Col key={index} onClick={item.onClick}>
                        <SelectItem value={item.label}>{item.label}</SelectItem>
                    </Col>
                ),
            )}
        </DropdownMenuRoot>
    );
}
