import { ReactNode } from "react";
import { LoginFormFields } from "../../../../auth/containers/LoginForm/LoginForm";
import { RegisterFormFields } from "../../../../auth/containers/RegisterForm/RegisterForm";
import { ResetPasswordFormFields } from "../../../../auth/components/input/ResetPasswordForm";
import { VerifyEmailFormFields } from "../../../../auth/containers/VerifyEmailForm/VerifyEmailForm";
import { SendEmailFormFields } from "../../../../auth/components/input/SendEmailForm";
import { spacing } from "../../../../config/theme/spacing";
import { Col, Form } from "@peersyst/react-components";

export type CardFormData = LoginFormFields | RegisterFormFields | ResetPasswordFormFields | VerifyEmailFormFields | SendEmailFormFields;

export interface CardFormProps {
    onSubmit: (data: any) => void;
    children: ReactNode;
}
export default function CardForm({ onSubmit, children }: CardFormProps): JSX.Element {
    return (
        <Col flex={1}>
            <Form onSubmit={onSubmit}>
                <Col gap={spacing[7]}>{children}</Col>
            </Form>
        </Col>
    );
}
