import { useQuery } from "react-query";
import ControllerFactory from "../../adapter/ControllerFactory";
import { Queries } from "../../query/queries";
import useUserState from "ui/adapter/state/useUserState";

export default function useUserInfo() {
    const { loggedIn } = useUserState();

    return useQuery(Queries.GET_USER_INFO, () => ControllerFactory.userController.info(), {
        enabled: loggedIn,
    });
}
