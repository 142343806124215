import { CompleteDAppDto } from "../../../common/models";
import DApp from "./DApp";
import { ICompleteDApp } from "../../../ui/adapter/entity/IDApp";
export default class CompleteDApp extends DApp implements ICompleteDApp {
    apiSecret: string;

    constructor() {
        super();
        this.apiSecret = "";
    }

    static fromDto(dAppDto: CompleteDAppDto): CompleteDApp {
        return {
            id: dAppDto.id,
            name: dAppDto.name,
            description: dAppDto.description,
            email: dAppDto.email,
            image: dAppDto.image,
            projectUrl: dAppDto.projectUrl,
            supportUrl: dAppDto.supportUrl,
            apiKey: dAppDto.apiKey,
            apiSecret: dAppDto.apiSecret,
            termsUrl: dAppDto.termsUrl,
            privacyPolicyUrl: dAppDto.privacyPolicyUrl,
            createdAt: dAppDto.createdAt,
            updatedAt: dAppDto.updatedAt,
        };
    }
}
