import { CompleteTransactionRequestDto, CreateTransactionRequestBody, SignInRequestDto } from "common/models";
import { ISignInRequestApi } from "domain/adapter/api/ISignInRequestApi";
import DomainError from "domain/error/DomainError";
import { IPlaygroundController } from "ui/adapter/controllers/IPlaygroundController";
import { PlaygroundCredentialsState } from "../state/playgroundCredentialsState";
import State from "domain/common/State";
import HmacEncrypter from "../utils/HmacEncrypter";
import DomainErrorCodes from "domain/error/DomainErrorCodes";
import { ITransactionRequestApi } from "domain/adapter/api/ITransactionRequestApi";
import { PlaygroundSignInRequestsState } from "../state/playgroundSignInRequestsState";
import { SimpleTransactionRequestDto } from "common/models/api/models/SimpleTransactionRequestDto";
import { IPlaygroundApi } from "domain/adapter/api/IPlaygroundApi";

export class PlaygroundController implements IPlaygroundController {
    constructor(
        private readonly signInRequestApi: ISignInRequestApi,
        private readonly playgroundCredentialsState: State<PlaygroundCredentialsState>,
        private readonly transactionRequestApi: ITransactionRequestApi,
        private readonly playgroundSignInRequestsState: State<PlaygroundSignInRequestsState>,
        private readonly playgroundApi: IPlaygroundApi,
    ) {}

    public async getSignInRequestLogs(): Promise<SignInRequestDto[]> {
        try {
            const { apiKey, valid } = this.playgroundCredentialsState.getState();
            if (!valid) throw new DomainError(DomainErrorCodes.INVALID_CREDENTIALS);
            return await this.signInRequestApi.getSignInRequestsByDApp(apiKey!);
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async getTransactionRequestLogs(): Promise<CompleteTransactionRequestDto[]> {
        try {
            const { apiKey, valid } = this.playgroundCredentialsState.getState();
            if (!valid) throw new DomainError(DomainErrorCodes.INVALID_CREDENTIALS);
            return await this.transactionRequestApi.getTransactionRequestsByDApp(apiKey!);
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async generateSignInRequest(): Promise<SignInRequestDto> {
        try {
            const timestamp = new Date().getTime().toString();
            const { apiKey, apiSecret, valid } = this.playgroundCredentialsState.getState();
            if (!valid) throw new DomainError(DomainErrorCodes.INVALID_CREDENTIALS);
            const signInRequest = await this.signInRequestApi.createSignInRequest(
                timestamp,
                HmacEncrypter.encrypt(timestamp, apiSecret!),
                apiKey,
            );
            const { signInRequests } = this.playgroundSignInRequestsState.getState();
            this.playgroundSignInRequestsState.setState({ signInRequests: [...signInRequests, signInRequest] });
            return signInRequest;
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async validateDAppCredentials(apiKey: string, apiSecret: string): Promise<boolean> {
        const timestamp = new Date().getTime().toString();
        const { verified } = await this.playgroundApi.verify(timestamp, HmacEncrypter.encrypt(timestamp, apiSecret), apiKey);
        this.playgroundCredentialsState.setState({ apiKey, apiSecret, valid: verified });
        return verified;
    }

    public async generateTransactionRequest({
        transaction,
        ...requestBody
    }: CreateTransactionRequestBody): Promise<SimpleTransactionRequestDto> {
        try {
            const timestamp = new Date().getTime().toString();
            const { apiKey, apiSecret, valid } = this.playgroundCredentialsState.getState();
            if (!valid) throw new DomainError(DomainErrorCodes.INVALID_CREDENTIALS);
            const txSkeleton = await this.transactionRequestApi.generateTransactionSkeleton(transaction);
            return await this.transactionRequestApi.createTransactionRequest(
                {
                    ...requestBody,
                    transaction: txSkeleton,
                },
                timestamp,
                HmacEncrypter.encrypt(timestamp, apiSecret!),
                apiKey,
            );
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }

    public async generateNftTransactionRequest({
        transaction,
        ...requestBody
    }: CreateTransactionRequestBody): Promise<SimpleTransactionRequestDto> {
        try {
            const timestamp = new Date().getTime().toString();
            const { apiKey, apiSecret, valid } = this.playgroundCredentialsState.getState();
            if (!valid) throw new DomainError(DomainErrorCodes.INVALID_CREDENTIALS);
            const txSkeleton = await this.transactionRequestApi.generateNftTransactionSkeleton(transaction);
            return await this.transactionRequestApi.createTransactionRequest(
                {
                    ...requestBody,
                    transaction: txSkeleton,
                },
                timestamp,
                HmacEncrypter.encrypt(timestamp, apiSecret!),
                apiKey,
            );
        } catch (e: any) {
            throw DomainError.fromApiError(e);
        }
    }
}
