import styled, { css } from "styled-components";
import { Typography } from "@peersyst/react-components";

export const GradientBanner = styled(Typography)(
    ({ theme }) => css`
        background: ${theme.palette.gradient.greenViolet};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    `,
);
