import styled, { css } from "styled-components";
import { AppBar } from "@peersyst/react-components";

export const AppBarRoot = styled(AppBar).attrs({ position: "fixed", elevation: 0 })(
    ({ theme }) => css`
        background: ${theme.palette.background};

        .AppBarContent {
            max-width: var(--page-max-width);
            margin: 0 auto;
            padding: 0 var(--horizontal-page-padding);
        }

        .Toolbar {
            height: var(--appbar-height);
            margin: 2rem auto 1.5rem;
            padding: 0 var(--horizontal-appbar-padding);
            background-color: ${theme.palette.components.appBar};
            border-radius: ${theme.borderRadiusMd};
        }
    `,
);
