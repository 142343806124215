import { createStore } from "zustand/vanilla";

export interface PlaygroundCredentialsState {
    apiKey: string | undefined;
    apiSecret: string | undefined;
    valid: boolean;
}

const playgroundCredentialsState = createStore<PlaygroundCredentialsState>(() => ({
    apiKey: undefined,
    apiSecret: undefined,
    valid: false,
}));

export default playgroundCredentialsState;
