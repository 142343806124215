import styled, { css } from "styled-components";
import { Button, ButtonProps } from "@peersyst/react-components";
import { ButtonSizeStyle, ButtonVariantStyle } from "./Button.types";
import { spacing } from "../../../../config/theme/spacing";

const primaryAppearance = css(
    ({ theme }) => css`
        color: ${theme.palette.white};
        background: ${theme.palette.gradient.greenDarkGreen};
        border: 0;
        transition: filter 0.1s;

        &:hover {
            filter: brightness(1.2);
        }

        &:active {
            filter: brightness(0.9);
        }
    `,
);
const secondaryAppearance = css(
    ({ theme }) => css`
        color: ${theme.palette.white};
        background-color: ${theme.palette.black[30]};
        transition: all 0.1s;

        &:hover {
            filter: brightness(1.2);
        }

        &:active {
            filter: brightness(0.9);
        }
    `,
);

const tertiaryAppearance = css(
    ({ theme }) => css`
        color: ${theme.palette.white};
        background-color: ${theme.palette.red};
        transition: all 0.1s;

        &:hover {
            filter: brightness(1.2);
        }

        &:active {
            filter: brightness(0.9);
        }
    `,
);

const outlinedAppearance = css(
    ({ theme }) => css`
        background: transparent;
        border: 1px solid ${theme.palette.overlay[100]["12%"]};
        color: ${theme.palette.black[30]};

        &:hover {
            color: ${theme.palette.white};
            background-color: ${theme.palette.black[30]};
        }

        &:active {
            filter: brightness(0.8);
        }
    `,
);

const glassVariant = css(({ theme }) => {
    return css`
        background: ${theme.palette.overlay[300]["24%"]};
        box-shadow: 0 0 12px ${theme.palette.black[30]};
        backdrop-filter: blur(8px);
        color: ${theme.palette.white};
        transition: color 0.1s ease-in-out;
        &:hover {
            color: ${theme.palette.black[10]};
            background: ${theme.palette.white};
            transition: color 0.1s ease-in-out;
        }
        &:active {
            color: ${theme.palette.black[30]};
            background: ${theme.palette.white};
        }
    `;
});

const filledVariant = css(({ theme }) => {
    return css`
        background: ${theme.palette.overlay[100]["6%"]};
        color: ${theme.palette.black[30]};
        &:hover {
            color: ${theme.palette.black[20]};
            background: ${theme.palette.overlay[100]["12%"]};
        }
        &:active {
            color: ${theme.palette.black[30]};
            box-shadow: 0 0 4px ${theme.palette.black[30]};
            background: ${theme.palette.white};
        }
    `;
});

const buttonAppearances: ButtonVariantStyle = {
    filled: filledVariant,
    primary: primaryAppearance,
    secondary: secondaryAppearance,
    tertiary: tertiaryAppearance,
    outlined: outlinedAppearance,
    glass: glassVariant,
};

const lgSize = css(
    ({ theme }) => css`
        height: 3.25rem;
        ${theme.typography.h5Regular.style}
    `,
);

const mdSize = css(
    ({ theme }) => css`
        height: 2.75rem;
        ${theme.typography.h5Regular.style}
    `,
);

const smSize = css(
    ({ theme }) => css`
        height: 1.5rem;
        ${theme.typography.h5Regular.style}
    `,
);

const buttonSizes: ButtonSizeStyle = {
    sm: smSize,
    md: mdSize,
    lg: lgSize,
};

export const ButtonRoot = styled(Button)<ButtonProps>(({ variant, size }) => {
    return css`
        font-size: 0.67rem;
        font-weight: 700;
        text-transform: none;

        height: 40px;
        padding: 0 ${spacing[6]};

        border-radius: 9999px;

        &:disabled {
            filter: unset;
            opacity: 0.4;
        }

        &.Loading {
            opacity: 1;
        }

        &:active {
            box-shadow: none;
        }

        ${buttonAppearances[variant || "primary"]}
        ${buttonSizes[size || "lg"]}
    `;
});
