import { Typography } from "@peersyst/react-components";

interface TitleProps {
    title: string;
}

export default function Title({ title }: TitleProps): JSX.Element {
    return (
        <Typography variant="h5Light" textTransform="uppercase">
            {title}
        </Typography>
    );
}
