import { Link as RouterLink } from "react-router-dom";
import { LinkProps } from "./Link.types";
import { Typography } from "@peersyst/react-components";
import clsx from "clsx";

export default function Link({ to, children, variant, style, className, ...rest }: LinkProps): JSX.Element {
    return (
        <RouterLink to={to} style={style} className={clsx(className, "Link")}>
            <Typography variant={variant} singleLine {...rest}>
                {children}
            </Typography>
        </RouterLink>
    );
}
