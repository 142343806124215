import { Typography } from "@peersyst/react-components";
import TextField from "../../../common/components/input/TextField/TextField";
import TextFieldLabel from "../../../common/components/input/TextFieldLabel/TextFieldLabel";
import useTranslate from "../../../locale/hooks/useTranslate";
import { useState } from "react";
import config from "../../../../common/config/config";
import Button from "../../../common/components/input/Button/Button";
import { ResendEmailButton } from "../../containers/VerifyEmailForm/VerifyEmailForm.styles";
import CardForm from "../../../common/components/layout/CardForm/CardForm";

export interface ResetPasswordFormFields {
    password: string;
    confirmPassword: string;
    emailToken: string;
}

export interface ResetPasswordFormProps {
    onSubmit: (data: ResetPasswordFormFields) => void;
    onResend: () => void;
    loading: boolean;
}

export default function ResetPasswordForm({ onSubmit, onResend, loading }: ResetPasswordFormProps): JSX.Element {
    const translate = useTranslate();

    const translateError = useTranslate("error");
    const [password, setPassword] = useState("");

    return (
        <CardForm onSubmit={onSubmit}>
            <TextField
                label={<TextFieldLabel label={translate("emailToken")} />}
                type="text"
                name="emailToken"
                placeholder="your token"
                validators={{ minChars: 8 }}
            />
            <TextField
                label={<TextFieldLabel label={translate("newPassword")} />}
                type="password"
                name="password"
                value={password}
                placeholder="new password"
                onChange={setPassword}
                validators={{ password: true }}
            />
            <TextField
                label={translate("confirmNewPassword")}
                type="password"
                name="confirmPassword"
                placeholder="new password confirmation"
                validators={{
                    eq: [password, translateError("passwordsDoNotMatch")],
                    password: true,
                    maxChars: config.passwordMaxLength,
                }}
            />
            <Typography variant="h5Regular" textAlign="center" light>
                {translate("didntReceiveEmail")}
                <ResendEmailButton variant="h5Regular" onClick={onResend}>
                    {translate("resendEmail")}
                </ResendEmailButton>
            </Typography>
            <Button type="submit" variant="primary" loading={loading} disabled={loading}>
                {translate("resetPassword")}
            </Button>
        </CardForm>
    );
}
