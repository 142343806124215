import { useMutation, useQueryClient } from "react-query";
import ControllerFactory from "../../adapter/ControllerFactory";
import { AuthRoutes } from "../router/AuthRouter";
import { useNavigate } from "react-router-dom";
import { Queries } from "../../query/queries";

export default function useLogout() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(() => ControllerFactory.authController.logout(), {
        onSuccess: () => {
            queryClient.invalidateQueries([Queries.GET_DAPPS]);
            queryClient.invalidateQueries([Queries.GET_USER_INFO]);
            navigate(AuthRoutes.LOGIN);
        },
    });
}
