import { SignInRequestDto } from "common/models";
import { createStore } from "zustand/vanilla";

export interface PlaygroundSignInRequestsState {
    signInRequests: SignInRequestDto[];
}

const playgroundSignInRequestsState = createStore<PlaygroundSignInRequestsState>(() => ({
    signInRequests: [],
}));

export default playgroundSignInRequestsState;
