import { createConfig } from "@peersyst/react-components";
import lightTheme from "./theme/lightTheme";
import { config } from "../../common/config";

const uiConfig = createConfig({
    projectName: config.projectName,
    themes: {
        default: lightTheme,
    },
});

export default uiConfig;
