import { createStore } from "zustand/vanilla";
import { ICompleteDApp } from "../../../ui/adapter/entity/IDApp";

export type IDAppState = Partial<Pick<ICompleteDApp, "id" | "name" | "apiKey" | "apiSecret">>;

/**
 * State used to update the edit dApp form
 */
const dAppState = createStore<IDAppState>(() => ({ id: undefined, name: undefined, apiKey: undefined, apiSecret: undefined }));

export default dAppState;
