import { Row } from "@peersyst/react-components";
import FullLogo from "../../../common/components/display/FullLogo/FullLogo";
import { AppRoutes } from "../../../dapp/router/DAppRouter";
import RecoverPasswordCard from "../../components/display/RecoverPasswordCard/RecoverPasswordCard";

export default function RecoverPasswordPage() {
    return (
        <Row wrap wrapGap="4rem" flex={1} gap="12rem" justifyContent="center" alignItems="center">
            <FullLogo link={AppRoutes.MY_APPS} />
            <RecoverPasswordCard />
        </Row>
    );
}
